/* add new audit starts here */
.in-progress-audit {
  padding: 30px 0 20px 0;
}

.in-progress-audit a {
  text-decoration: underline;
  font-size: 16px;
  color: #005daa;
}

.in-progress-audit span {
  color: rgba(0, 0, 0, 0.8);
  font-size: 16px;
}

.plant-summary-wrapper {
  border-radius: 3px;
  border: solid 0.5px #979797;
  border: 1px rgba(151, 151, 151, 0.3);
}

.plant-summary ul {
  padding: 22px 15px;
  background-color: #ffffff;
  display: flex;
  flex-wrap: wrap;
}

.plant-summary ul li {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.8);
  flex: 0 0 33.333%;
  max-width: 33.333%;
  @include respond-to("small") {
    flex: inherit;
    max-width: 100%;
    margin-bottom: 10px;
    margin-right: 10px;
  }
}

.plant-summary ul li:nth-child(-1n + 6) {
  margin-bottom: 25px;
  @include respond-to("small") {
    margin-bottom: 10px;
    margin-right: 10px;
  }
}

.add-task-tble,
td,
th {
  border: 1px solid black;
}
.add-task-tble tr th {
  width: 120px;
  max-width: 100%;
}
.add-task-tble,
.gen-audt-mch-sts {
  border-collapse: collapse;
  width: 100%;
  border: none;
  border: 1px solid grey;
  @include respond-to("large") {
    min-width: 800px;
  }

  &.large-table {
    @include respond-to("large") {
      min-width: 1200px;
    }
  }
}

.add-task-tble tr {
  border-bottom: solid 0.5px #979797;
  text-align: center;
}
.add-task-tble tr:last-child {
  border-bottom: none;
}
.add-task-tble th {
  background-color: rgba(0, 98, 152, 0.1);

  border: none;
  border: 1px solid grey;
  padding: 11px 0;
}
.add-task-tble td {
  background-color: #ffffff;
  border: none;
  border-left: 1px solid grey;
  /* padding:11px 0; */
  font-size: 14px;
}

.add-task-outer {
  min-height: 335px;
  background-color: #ffffff;
}

.add-task-outer {
  position: relative;
}

.add-task-outer center {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  margin-right: -50%;
  left: 50%;
}

.add-task-outer figure img {
  padding: 7px;
}

.add-task-outer figcaption {
  color: #006298;
  font-size: 14px;
}

.submition-outer {
  button,
  a {
    margin-left: 15px;
  }
}

.submition-outer button:not(:first-child) {
  margin-left: 15px;
  @include respond-to("xsmall") {
    margin-left: 7px;
  }
}

.submition-outer .btn-prmry {
  min-width: 130px;
  padding: 11px 20px;
  margin: 0;
  @include respond-to("xsmall") {
    margin-top: 7px;
  }
}

.submition-outer .btn-prmry.disabled {
  opacity: 0.5;
}

.submition-outer .btn-scndry {
  padding: 11px 15px;
  font-size: 16px;
  color: #f28b00;
  border-radius: 21px;
  border: solid 1px #f28b00;
  background-color: #ffffff;
}

.submition-outer .btn-scndry:last-child {
  min-width: 130px;
}

.submition-outer .btn-scndry.disabled {
  opacity: 0.5;
}

tr a img {
  padding: 0 7px;
}

/* add new audit ends here */

th {
  font-size: 16px;
  padding: 11px 0;

  .form-control {
    height: 27px;
    line-height: 27px;
    padding: 5px;
  }
}
td {
  font-size: 14px;
  font-weight: 500;
  padding: 11px 0;

  .form-group {
    margin-bottom: 0;
  }

  .form-control {
    height: 28px;
    line-height: 27px;
    padding: 5px;
  }
  select {
    &.form-control {
      background-position: calc(100% - 7px) center;
      border: 1px solid $grey-border;
      min-width: 120px;
      padding: 0 5px;
      background-size: 12px;
    }
  }
}

table.gen-audt-mch-sts tr td select.form-control {
  border: 1px solid $grey-border;
}

.table-responsive {
  margin-bottom: 20px;
  word-break: break-word;

  @include respond-to("large") {
    width: 100%;
    overflow-x: auto;
  }
}
