$white: #ffffff;
$grey-border: #979797;
$orange: #f28b00;
$blue-btn: #005daa;
$btn-blue: #006298;
$red: #ff0000;
$green: #2c9c48;

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  @import url('https://fonts.googleapis.com/css?family=Muli:400,500,600,700&display=swap');

  $font-muli: 'Muli', sans-serif;

  body {
    font-family: $font-muli;
    font-weight: 400;
    background-color: rgba(0, 93, 170, 0.08);
    color: rgba(0, 0, 0, 0.8)
  }

  .login-background{
    width: 100%;
    height: 100vh;
    background: url(../images/login-background.png) no-repeat;
    background-size: cover;
  }

  ul, ol{
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  a {
    text-decoration: none;
    outline: none;

    &:hover{
        outline: none;
    }

    &:focus{
        outline: none;
    }
  }

  strong{
    font-weight: 700;
  }

  h1 {
    font-size: 36px;
  }
  
  h2 {
    font-size: 28px;
  }
  
  h3 {
    font-size: 20px;
  }

  details{
    outline: none;

    summary{
        &::-webkit-details-marker {
            display:none;
          }
    }
  }

  summary{
    outline: none;
    -webkit-appearance: none;
    appearance: none;
  }

  .mobile-menu{
    width: 46px;
    border: 2px solid $blue-btn;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 3px 0;
    cursor: pointer;
    display: none;
    position: absolute;
    right: 10px;
    top: 5px;
    @include respond-to('small') {
      display: flex;
    }
  
    span{
      display: block;
      width: 34px;
      height: 2px;
      background: $blue-btn;
      margin: 3px 0;
    }
  }