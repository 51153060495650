input,
select,
button {
  outline: none;
  font-family: $font-muli;
  &:focus {
    outline: none;
  }
}

button {
  border: 0;
  cursor: pointer;
}

label {
  font-family: $font-muli;
  font-weight: 700;
}

.form-group {
  margin-bottom: 15px;
  position: relative;
  width: 100%;

  label {
    margin-bottom: 10px;
    display: block;
  }
}

.form-control {
  width: 100%;
  height: 60px;
  line-height: 60px;
  font-family: $font-muli;
  font-size: 14px;
  font-weight: 500;
  background: $white;
  border: 1px solid $grey-border;
  border-radius: 3px;
  padding: 6px 15px;

  &.error {
    border-color: $red;
  }

  // &:disabled{
  //   background: rgba(0, 0, 0, 0.05);
  //   cursor: not-allowed;
  // }
}

input {
  &[name="estimatedDate"] {
    background: $white url(../images/calendar.png) no-repeat calc(100% - 15px) center;

    // &:disabled{
    //   background: rgba(0, 0, 0, 0.05) url(../images/calendar.png) no-repeat calc(100% - 15px) center;
    // }
  }
}

.xs-font {
  font-size: 12px;
}

select {
  &.form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 0 29px 0 13px;
    background: $white url(../images/down-arrow.png) no-repeat calc(100% - 13px) center;

    // &:disabled{
    //   background: rgba(0, 0, 0, 0.05) url(../images/down-arrow.png) no-repeat calc(100% - 13px) center;
    // }
  }
}

.forgot-password {
  color: $orange;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 35px;
  text-decoration: underline;
  @include respond-to('small') {
    margin-bottom: 20px;
  }
}

.login-btn {
  .btn-prmry {
    width: 100%;
    padding: 19px 30px;
    font-size: 18px;
    border-radius: 30px;
    @include respond-to('small') {
      border-radius: 3px;
    }
  }
}

.request-access {
  display: flex;
  justify-content: center;
  margin-top: 15px;

  a {
    font-size: 16px;
    color: $orange;
    text-decoration: underline;
    font-weight: 700;
  }
}

.btn-blue {
  background: $btn-blue;
  font-weight: 700;
  font-size: 18px;
  color: $white;
  min-width: 125px;
  height: 41px;
  line-height: 41px;
  border-radius: 5px;
  padding: 0 15px;
}

.btn-prmry {
  background-color: $orange;
  padding: 11px 36px;
  border-radius: 20px;
  color: $white;
  font-weight: 700;
  font-size: 16px;
}

.react-datepicker-wrapper {
  display: flex;
}

.select-your-id {
  position: relative;
  top: -25px;
  left: -19px;
  font-size: 14px;
  span {
    color: $red;
  }
}

.user-permission-radio {
  position: relative;
  top: -20px;
  display: flex;
  align-items: center;
  justify-content: center;

  input {
    display: none;

    & + label {
      font-size: 10px;
      border: 1px solid $orange;
      color: rgba(0, 0, 0, 0.8);
      cursor: pointer;
      min-width: 82px;
      margin-right: 7px;
      text-align: center;
      padding: 8px 0;
      border-radius: 3px;
    }

    &:checked {
      & + label {
        background: $orange;
        color: $white;
      }
    }
  }

  & + .srv-validation-message {
    margin-top: -18px;
    margin-left: -19px;
    margin-bottom: 20px;
  }
}

.srv-validation-message {
  color: $red;
}

.srv-validation-correct-message {
  color: $green;
}

.react-datepicker__input-container {
  input {
    background: $white url(../images/calendar.png) no-repeat calc(100% - 8px) center;
    padding: 6px 8px;
  }
}

.popup-multi-btn {
  display: flex;
  justify-content: space-between;
  button {
    margin: 0 20px;
  }
}

.rqst-accs-popup {
  .popup-multi-btn {
    .btn-rqst-accs {
      // margin-left: 20px;
      // margin-right: 20px;
      &.btn-cancel {
        // margin-left: 0;
        background-color: #ffce9d;
        border: 1px solid #f08b25;
        color: #f08b25;
      }
      // &:last-child {
      //   margin-right: 0;
      // }
    }
  }
}

#create-audit-popup{
  .form-cntnt{
    @include respond-to('small') {
      max-height: 250px;
      overflow-y: auto;
    }
    @include respond-to('xsmall') {
      max-height: 450px;
    }
  }

  .crt-odi-btn-otr{
    @include respond-to('small') {
      padding: 10px 0;
    }
  }

  .btn-prmry{
    @include respond-to('small') {
      width: 100%;
      border-radius: 3px;
      padding: 19px 0;
    }
  }
}

.from-control-text{
  width: 100%;
  text-align: left;
}

.data-card-row{
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  .from-control-text{
    width: 55%;
    text-align: left;
  }

  .form-control{
    height: 30px;
    line-height: 30px;
    width: 55%;
    padding: 6px 8px;
  }

  .date-control{
    width: 55%;
    .form-control{
      width: 100%;
    }
  }

  select{
    &.form-control{
      line-height: 18px;
      background-position: calc(100% - 8px) center;
    }
  }

  textarea{
    &.form-control{
      min-height: 60px;
      line-height: 17px;
      -webkit-resize: none;
      -moz-resize: none;
      resize: none;
    }
  }

}
