// Login Start
.login-main {
    display: flex;
    flex-direction: column;
    align-items: center;

    .login-logo {
        width: 224px;
        margin: 64px auto 19px;
        display: block;
    @include respond-to("small") {
            width: 130px;
            margin: 15px auto 10px;
        }

        img {
            max-width: 100%;
        }
    }

    h1 {
        font-size: 26px;
        color: rgba(0, 0, 0, 0.8);
        font-weight: 600;
        margin-bottom: 19px;
    }
}
.login-form-container {
    width: 100%;
    max-width: 606px;
    padding: 45px 55px;
    border-radius: 10px;
    background: $white;
    box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.2);

  @include respond-to("small") {
        max-width: 96%;
        padding: 10px 15px;
    }

    .login-icon {
        line-height: 37px;
        display: block;
        margin-bottom: 0;
    }

    .email-icon {
        background: url(../images/email-icon.png) no-repeat left center;
        padding-left: 27px;
    }
    .password-icon {
        background: url(../images/password-icon.png) no-repeat left center;
        padding-left: 25px;
    }

    .user-icon {
        background: url(../images/user-icon.png) no-repeat left center;
        padding-left: 25px;
    }

    .designation-icon {
        background: url(../images/designation-icon.png) no-repeat left center;
        padding-left: 31px;
    }

    .plant-icon {
        background: url(../images/plant-icon.png) no-repeat left center;
        padding-left: 21px;
    }
}

.request-access-main {
    .login-form-container {
        max-width: 600px;

        .btn-prmry {
            margin-top: 57px;
        }
    }
}

.login-row {
    display: flex;
    justify-content: space-between;
}
/////////////////////////////////////////akshay
// .login-column{
//   width: calc(50% - 15px);
// }
// Login Close

// Form Row Column Start
.form-row {
    display: flex;
    //justify-content: space-between;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  @include respond-to("small") {
        flex-wrap: wrap;
        margin-right: 0;
        margin-left: 0px;
    }

    &.task-row {
        justify-content: flex-start;
    }
}
//////////////////////////////////////////////akshay
// .login-column{
//   width: calc(50% - 15px);
// }

.column-left {
    margin-top: 22px;
    width: 50%;
    float: left;
}

.column-right {
    width: 50%;
    float: right;
}

.column-two {
    //width: calc(50% - 15px);
    width: 50%;
    padding: 0 15px;
}
.column-three {
    //width: calc(33.33333333% - 15px);
    width: 33.33333333%;
    padding: 0 15px;
  @include respond-to("medium") {
        width: 50%;
    }
  @include respond-to("small") {
        width: 100%;
        padding: 0;
    }
}

.column-four {
    //width: calc(25% - 10px);
    width: 25%;
    //margin-left: 10px;
    padding: 0 15px;
    margin-bottom: 20px;
    display: flex;

    // &:first-child(1n) {
    //   margin-left: 0;
    // }

    // &::nth-child(1n) {
    //   margin-left: 0;
    // }
  @include respond-to("large") {
        width: 33.33333333%;
    }

  @include respond-to("medium") {
        width: 50%;
    }

  @include respond-to("small") {
        width: 100%;
        padding: 0;
    }
}

.summary-rows {
    width: 100%;
    padding: 5px;
    border-radius: 3px;
    background: $white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
  @include respond-to("small") {
        padding: 15px;
    }

    li {
        margin-bottom: 17px;
        font-size: 14px;
        font-weight: 500;
    }

    span {
        max-width: 140px;
        font-size: 13px;
        font-weight: 500;
        margin-bottom: 5px;
        display: inline-block;
    }

    .upload-file-control {
        margin-bottom: 17px;
        input {
            & + label {
                display: flex;
                margin-left: 0;
            }
        }
    }

    .btn-prmry {
        padding: 7px 18px;
        font-size: 14px;
    }

    .form-group {
        margin-bottom: 10px;
    }

    .form-control {
        height: 30px;
        line-height: 30px;
    }

    .group-upload-images {
        top: -5px;
    }

    .summary-btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 4px;
        flex-direction: row;

        a {
            color: $blue-btn;
            font-weight: 700;
            font-size: 14px;
            text-decoration: underline;
        }

        .btn {
            margin: 0 2px;

            &:first-child {
                margin: 0 2px 0 0;
            }

            &:last-child {
                margin: 0 0 0 2px;
            }
        }
    }
}

.summary-boxes {
    width: 100%;
    padding: 20px;
    border-radius: 3px;
    background: $white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
  @include respond-to("small") {
        padding: 15px;
    }

    li {
        margin-bottom: 17px;
        font-size: 14px;
        font-weight: 500;
    }

    span {
        max-width: 140px;
        font-size: 13px;
        font-weight: 500;
        margin-bottom: 5px;
        display: inline-block;
    }

    .upload-file-control {
        margin-bottom: 17px;
        input {
            & + label {
                display: flex;
                margin-left: 0;
            }
        }
    }

    .btn-prmry {
        padding: 7px 18px;
        font-size: 14px;
    }

    .form-group {
        margin-bottom: 10px;
    }

    .form-control {
        height: 30px;
        line-height: 30px;
    }

    .group-upload-images {
        top: -5px;
    }

    .summary-btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 4px;
        flex-direction: row;

        a {
            color: $blue-btn;
            font-weight: 700;
            font-size: 14px;
            text-decoration: underline;
        }

        .btn {
            margin: 0 2px;

            &:first-child {
                margin: 0 2px 0 0;
            }

            &:last-child {
                margin: 0 0 0 2px;
            }
        }
    }
}

.plant-list {
    width: 100%;
    padding: 0 26px;

    li {
        margin-bottom: 10px;
    }

    a {
        width: 100%;
        padding: 21px 15px;
        text-align: left;
        border-radius: 3px;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
        background-color: $white;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.8);
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

// Form Row Column Close

.submition-outer {
    text-align: right;
    padding: 20px 0 48px 0;
}

.mr {
    margin-right: 31px;
}

.container {
    padding: 0 26px;
  @include respond-to("large") {
        padding: 0 15px;
    }
  @include respond-to("small") {
        padding: 0 10px;
    }
}

.bd-none {
    border: none;
}

.login-page-wrap {
    background-image: url(../images/login-background.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 85px 0;
}

.logo-cntnr {
    text-align: center;
    margin-bottom: 30px;
}

.form-wrap {
    width: 606px;
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
    padding: 55px 45px;
    border-radius: 10px;
    box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
}

/* request access styling starts here */

.rqst-accs-frm-wrap {
    width: 1133px !important;
}

.flex-form-group {
    display: flex;
}

.flex-form-group .left-form-wrap {
    width: 50%;
}

.flex-form-group .right-form-wrap {
    width: 50%;
}

/* request access styling ends here */

/* rqst acces popup starst here */
.pop-up-bg {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    //display: none;
}
.rqst-accs-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 3px;
    background-color: #ffffff;
    min-width: 540px;
    width: 100%;
    max-width: 80%;
    margin: 0 auto;
  @include respond-to("xsmall") {
        min-width: 294px;
    }
}

.styles_closeIcon__1QwbI {
    display: none;
}

#create-audit-popup .rqst-accs-popup {
    width: auto;
}

.v {
    display: block;
}

.block {
    display: block;
}

.rqst-accs-popup .popup-cntnt {
    padding: 50px 1px;
    text-align: center;
  @include respond-to("xsmall") {
        padding: 30px 1px;
    }
}
.rqst-accs-popup .popup-cntnt h3 {
    margin-bottom: 15px;
}
.rqst-accs-popup .btn-rqst-accs {
    border-radius: 0;
    margin: 0;
    width: 594px;
}

/* rqst acces popup starst here */

/* request access styling ends here */
.hdr-cntnt-wrap {
    background-color: white;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
}

.hdr-cntnt {
    display: flex;
    padding: 7px 0;
    align-items: center;
    justify-content: space-between;
}
/* audit manager existing audit starts here */

/* audit manager starts here */

// .plants .plant {
//     width: 100%;
//     max-width: 100%;
//     padding: 21px 15px;
//     text-align: left;
//     border-radius: 3px;
//     box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
//     background-color: #ffffff;
//   }
//   .plants .plant summary span{
//   float: right;
//   cursor: pointer;
//   outline: none;
//   }

//   .plants .plant summary span img{
//     outline: none;
//   }

/* audit manager ends here */

/* create  audit starts here  */

.create-audit-outer {
    display: block;
    width: 1097px;
    max-width: 100%;
    background-color: #ffffff;
    margin: 0 auto;

    &.scaner-modal {
        width: auto;
    }

    .top-cntnt {
        display: flex;
        justify-content: space-between;
        padding: 10px 15px;
        background-color: $orange;
        align-items: center;

        &.right {
            justify-content: flex-end;
        }

        a {
            display: flex;
        }
    }
}

.create-audit-outer .crt-odi-btn-otr {
    display: flex;
    flex-direction: row-reverse;
}

.crt-odi-btn-otr {
    margin-top: 10px;
    display: flex;
    flex-direction: row-reverse;
    padding: 25px 30px;
    float: right;
    align-items: flex-end;
}

.top-cntnt h4 {
    font-size: 18px;
    color: #ffffff;
}

.form-cntnt {
    padding: 24px 30px 10px 30px;
    /* border: solid 1px #979797; */

  @include respond-to("small") {
        padding: 15px;
    }
    .submition-outer {
        padding: 0;
    }
}

.form-button {
    margin-top: 0px;
    padding-top: 1px;
    padding-left: 57px;
}
/* create  audit ends here  */

/* new audit add task start here */
.add-task-outer {
    .add-task-frm-grp {
        display: flex;
        flex-wrap: wrap;
    }
}

.add-task-outer .form-cntnt {
    padding: 30px 30px 30px 30px;
  @include respond-to("small") {
        padding: 15px;
    }
}

.add-task-outer .add-task-frm-grp .form-group {
    flex: 0 0 33.33%;
    max-width: 33.33%;
}

.add-task-outer .add-task-frm-grp .form-group:last-of-type {
    margin-top: 0;
}

.add-task-frm-grp .form-group select {
    width: 400px;
    max-width: 100%;
}

.add-task-outer .add-task-frm-grp .form-group:nth-child(-1n + 6) {
    margin-bottom: 40px;
}

/* new audit add task end here */

/* work order  start here */
.work-order-wrap {
    max-width: 100%;
}

.work-order-tabs-outer {
    padding: 20px 30px 30px;
    max-width: 100%;
    background-color: #ffffff;
  @include respond-to("small") {
        padding: 15px;
    }
}
.work-order-tabs {
    .btn-wrk-ordr {
        &.active {
            border: solid 1px #006298;
            background-color: rgba(0, 98, 152, 0.1);
        }
    }
}

.work-order-wrap {
    h4 {
        font-size: 18px;
        color: rgba(0, 0, 0, 0.8);
    }

    .headiong-outer {
        background-color: #f7f7f7;
        max-width: 100%;
        padding: 8px 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .work-order-tabs {
        button {
            min-width: 138px;
            background-color: #ffffff;
            max-width: 100%;
            font-size: 16px;
            padding: 11px 20px;
            border: solid 1px #979797;
            margin-left: 5px;
            margin-bottom: 5px;
            text-transform: capitalize;
            // &:first-letter{
            //   text-transform: uppercase;
            // }
            &:active {
                border: solid 1px #006298;
                background-color: rgba(0, 98, 152, 0.1);
                color: #006298;
            }
        }
    @include respond-to("xsmall") {
            display: none;
        }
    }
}

.work-order-wrap ul.rpair-slctn {
    border: 1px solid rgba(151, 151, 151, 0.5);
    border-radius: 3px;
}

.work-order-wrap ul.rpair-slctn li {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.8);
    padding: 20px 0 20px 22px;
    border-top: 1px solid rgba(151, 151, 151, 0.5);
    border-bottom: 1px solid rgba(151, 151, 151, 0.5);
}

ul.rpair-slctn li:not(:last-child) {
    margin-bottom: 10px;
}

.work-order-wrap form .btn-prmry {
    font-size: 16px;
    min-width: 130px;
    max-width: 100%;
    text-align: center;
    padding: 11px 20px;
    margin: 0;
    float: right;
    // margin-top: 20px;
    margin-bottom: 40px;
}

/* work order  end here */

/* blower audit start here */
.blower-tabs-outer {
    width: 100%;
    max-width: 100%;
}
.blower-tabs .btn-blowers {
    padding: 11px 0;
    color: rgba(0, 0, 0, 0.8);
    font-size: 16px;
    width: 254px;
    max-width: 100%;
    background-color: #ffffff;
    border: solid 1px #979797;
  @include respond-to("xsmall") {
        display: none;
    }
}
.blower-tabs .btn-blowers.active {
    border: solid 1px #006298;
    background-color: rgba(0, 98, 152, 0.1);
}

.pm-qlty-aasmnt {
    width: 203px;
    max-width: 100%;
}

.gnrl-adt {
    width: 286px;
    max-width: 100%;
}
.ga-mchn-status {
    padding-top: 20px;
}
.table-outer {
    display: none;
    padding-top: 20px;
    border-radius: 3px;
    border: 0.5px solid $grey-border;
    border-bottom: none;

    &.popup-outer {
        display: none;
        border-radius: 3px;
        border: none;
        max-height: 350px;
        overflow: auto;
        padding: 15px;
    }

    &.active {
        display: block;
    }

    &.preview {
        max-height: 530px;
        overflow: auto;
    }
}
.com-hlt-chup-tbl-otr {
    display: none;
}
table.gen-audt-mch-sts {
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;
    border-radius: 3px;
    border: solid 0.5px #979797;
    background-color: #ffffff;
    padding-top: 20px;
}
table.gen-audt-mch-sts tr th {
    border: solid 0.5px #979797;
    background-color: rgba(216, 216, 216, 0.25);
    padding: 10px 27px 10px 10px;
    font-size: 16px;
}
table.gen-audt-mch-sts tr td {
    padding: 10px 10px;
    font-size: 14px;
    border: 1px solid #979797;
}
table.gen-audt-mch-sts tr td select {
    border: none;
    background-color: #ffffff;
}
table.gen-audt-mch-sts tr th:last-of-type {
    width: 494px;
    max-width: 100%;
}

span.caf {
    font-size: 16px;

    font-weight: bold;
    color: #006298 !important;
}
.caf-otr {
    padding: 30px 14px 15px 14px;
}

.ccc-otr,
.krones-otr {
    border-left: solid 0.5px #979797;
    border-right: solid 0.5px #979797;
    background-color: #ffffff;
}

.last-tbl-vw tr th:last-child {
    width: 538px !important;
}

.loaderContainer {
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(0, 0, 0, 0.85);
    z-index: 999999;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: $white;
    font-weight: 700;
}

.data-upload-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    border: 1px solid $grey-border;
    margin-bottom: 10px;
  @include respond-to("small") {
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .data-upload-col {
        //width: calc(25% - 20px);
        // display: flex;
        align-items: center;
        margin-left: 10px;
        font-size: 14px;
        flex: 1;
    @include respond-to("small") {
            flex: auto;
            width: 100%;
            margin-left: 0;
            margin-bottom: 10px;
        }

        &.date-upload-col {
            display: flex;
            min-width: 232px;
        }
        &:first-child {
            margin-left: 0;
        }

        .upload-file-control {
            display: inherit;
      @include respond-to("small") {
                display: flex;
                label {
                    margin-right: 0;
                }
            }
        }
        // &:nth-child(1), &:nth-child(2){
        //   max-width: 300px;
        // }

        label {
            margin-right: 20px;
        }

        textarea {
            min-height: 56px;
            line-height: 1.4;
        }
    }

    .form-control {
        height: 40px;
        line-height: 40px;
        max-width: 230px;
    @include respond-to("small") {
            max-width: 100%;
        }
    }

  input[type="file"] {
        line-height: 26px;
        width: 230px;
    }

    .upload-file-label {
        margin-right: 20px;
        white-space: nowrap;

        span {
            display: block;
            font-size: 90%;
        }
    }
}

.upload-file-control {
    input {
        display: none;

        & + label {
            width: 100%;
            border: 1px solid $orange;
            background: $orange url(../images/upload-icon.png) no-repeat 15px center;
            background-size: 16px;
            color: $white;
            padding: 9px 15px 9px 40px;
            border-radius: 3px;
            cursor: pointer;
            white-space: nowrap;
            // margin-left: 30px;
        }
    }
}

.upload-images {
    width: 40px;
    height: 40px;
    margin-left: 10px;
    border: 1px solid $grey-border;
    cursor: pointer;

  @include respond-to("small") {
        margin: 0 10px 0 0;
    }
}

.question-form-container {
    margin-top: 30px;
    label {
        display: flex;
        margin-bottom: 5px;
    }
    .sub-ques {
        margin-left: 16px;
    }
}

.form-group-question {
    margin-bottom: 15px;

    .form-control {
        height: 40px;
        line-height: 40px;
        max-width: 176px;
    }
}

.img-preview {
    width: 500px;
    display: flex;
    justify-content: center;
  @include respond-to("small") {
        width: 100%;
    }
    img {
        max-width: 100%;
    }
}

.btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    button {
        margin: 0 10px;
    }
}

.styles_modal__gNwvD {
    max-width: 1100px;
    padding: 0.5rem;
    canvas {
        &.drawingBuffer {
            max-width: 100%;
            height: 250px;
        }

        & + video {
            width: 100%;
        }
    }

  @include respond-to("large") {
        max-width: 98%;
    }
}

/* blower audit end here */
.barcode-scanner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  @include respond-to("small") {
        flex-wrap: wrap;
        margin: 10px 0;
    }
    .form-group {
    @include respond-to("small") {
            justify-content: flex-start;
            margin: 5px 0;
        }
    }
}

.form-group .upload-file-control input + label {
    margin-left: 0;
    padding: 19px 15px 19px 40px;
}

.radio-group {
    display: flex;
    justify-content: space-between;
}

.custom-form-control {
    display: flex;

    input {
        display: none;

        & + label {
            width: 16px;
            height: 16px;
            display: inline-block;
            border: 1px solid $orange;
            cursor: pointer;
            margin-right: 4px;

            & + span {
                margin-right: 10px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        &:checked {
            & + label {
                background: $orange url(../images/true-check.png) no-repeat center center;
            }
        }

    &[type="radio"] {
            & + label {
                width: 18px;
                height: 18px;
                border-radius: 100%;
            }

            &:checked {
                & + label {
                    background: $orange;
                }
            }
        }
    }
}

.group-upload-images {
    display: flex;
    position: relative;
    top: 11px;
    left: -10px;
  @include respond-to("small") {
        position: static;
        margin: 10px 0 0 0;
    }
}

.static-date {
    min-width: 250px;
    padding: 10px;
}

.barcode-excel {
    display: flex;
  @include respond-to("small") {
        width: 100%;
        margin: 5px 0;
    }

    button {
        margin-right: 15px;
    }
    .btn-blue {
        white-space: nowrap;
    }
}

.maunual-colour-code {
    background-color: #f7f7f7;
}

.highlight-card {
    background-color: #faf60c;
}

.reviewerClass {
    background: #fff5e6;
}

.completedClass {
    background: #e6ffe6;
}

.reviewer-remark {
    // For Audit point that has reviewers comments
    background: #fff5e6;
}

.completed-info {
    // For completed answers
    background: #e6ffe6;
}

.missing-info {
    // For Auditors/Reviewers missing information
    background: #ffe6e6;
}

// --------------------------parts room item count-------------------------
.item-count-box {
    min-width: 100px;
    font-size: 16px;
    font-weight: 600;
    color: #000000;
  @include respond-to("small") {
        // margin-left: 45%;
        text-align: center;
    }
  @include respond-to("medium") {
        // margin-left: 45%;
    }
}

.count-box {
  @include respond-to("small") {
        display: flex;
        flex-direction: column;
    }
  @include respond-to("medium") {
        // display: flex;
        // flex-direction: column;
    }
}

// ---------------------------------- switch toggle button--------------------------------------

.toggle-btn-container-1 {
    display: inline-block;
    // position: relative;
    // float: right;
    // margin-left: 20px;
}

.toggle-btn-container-2 {
    position: relative;
    float: right;
    margin-left: 20px;
}

.toggle-btn {
    width: 41px;
    height: 21px;
    background: #808080;
    border-radius: 30px;
    padding: 5px;
}

.toggle-btn > .inner-circle {
    width: 12px;
    height: 12px;
    background: #ffffff;
    border-radius: 50%;
    transition: 600ms ease;
}

.toggle-btn.active {
    background: #00acee;
}

.toggle-btn.active > .inner-circle {
    margin-left: 20px;
    transition: 600ms ease;
}

.toggle-btn2 {
    width: 41px;
    height: 21px;
    background: #808080;
    border-radius: 30px;
    padding: 5px;
}

.toggle-btn2 > .inner-circle {
    width: 12px;
    height: 12px;
    background: #ffffff;
    border-radius: 50%;
    transition: 600ms ease;
}

.toggle-btn2.active {
    background: #00acee;
}

.toggle-btn2.active > .inner-circle {
    margin-left: 20px;
    transition: 600ms ease;
}

.toggle-lable {
    position: relative;
    float: left;
    font-weight: 600;
}

.toggle-block {
    display: grid;
    flex-direction: column;
    width: 100%;
    background: #ffffff;
    padding: 15px 15px 5px 5px;
    justify-content: flex-end;
    align-items: center;
  @include respond-to("xsmall") {
        flex-wrap: wrap;
        padding: 10px 15px;
    }
}

.toggle-row {
    margin-bottom: 10px;
    margin-left: 22px;
}

.toggle-block-1 {
    // position: absolute;
    // width: 210px;
    // top: 25px;
    // left: 50%;
    // @include respond-to("medium") {
    //   left: 32%;
    // }
  @include respond-to("xsmall") {
        width: 100%;
    }
}

.toggle-block-2 {
    // position: absolute;
    // width: 210px;
    // top: 25px;
    // left: 75%;
    // @include respond-to("medium") {
    //   left: 66%;
    // }
    margin-left: 50px;
  @include respond-to("xsmall") {
        width: 100%;
        margin: 10px 0 0 0;
    }
}

.quantifiable-block {
    width: 100%;
  @include respond-to("medium") {
        width: 80%;
    }
}

.blank-field {
    border-color: $red;
    &:active {
        border-color: $red;
    }
}

.upload-blank-field {
    border: 1px solid #ff0000 !important;
}

.tab-select {
    display: none;
    height: 40px;
    font-size: 16px;
    margin: 0 0 6px 4px;
    background-color: #ffffff;
    text-transform: capitalize;
  @include respond-to("xsmall") {
        display: block;
    }
}

.upload-section {
    display: flex;
    flex-direction: column;
    height: 300px;
    width: 400px;
    justify-content: space-around;
    align-items: center;
}

.react-thumbnail-generator {
    canvas {
        width: 100%;
        height: 100%;
    }

    video {
        display: none;
    }
}

.upload-media-modal {
    padding: 0px;

    .modal-header {
        padding: 20px 20px 10px;
        border-bottom: 1px solid gray;
    }

    .cross-icon {
        display: block;
    }
}

.margin-bottom-10 {
    margin-bottom: 10px;
}
