.left-logo {
  img {
    width: 96px;
  }
}

.profile-holder {
  select {
    border: none;
  }
}

.menu {
  position: relative;
  @include respond-to("small") {
    height: 44px;
  }
  ul {
    display: flex;
    // @include respond-to('medium') {
    //   justify-content: space-between;
    // }
    @include respond-to("small") {
      width: calc(100% - 20px);
      flex-wrap: wrap;
      display: none;
      position: absolute;
      top: 100%;
      right: 10px;
      z-index: 99;

      &:before {
        position: absolute;
        top: -6px;
        right: 0;
        content: "";
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 7px solid $blue-btn;
      }
    }
    &.show-mobile-menu {
      @include respond-to("small") {
        display: flex;
      }
    }
  }
  li {
    margin-right: 10px;
    // @include respond-to('medium') {
    //   width: calc(25% - 10px);
    // }
    @include respond-to("small") {
      margin: 0;
      width: 100%;
    }
    a {
      display: block;
      padding: 11px 0;
      min-width: 194px;
      font-size: 16px;
      background-color: $white;
      border: solid 0.3px rgba(151, 151, 151, 0.3);
      color: rgba(0, 0, 0, 0.8);
      text-align: center;
      @include respond-to("medium") {
        min-width: 184px;
      }
      @include respond-to("small") {
        text-align: left;
        padding: 10px 15px;
      }
    }

    &.active {
      a {
        background: $blue-btn;
        color: $white;
      }
    }
  }
}

.filter-wrap {
  text-align: center;
  form {
    margin-left: 30%;
  }

  .btn-apply {
    background-color: #006298;
    font-family: "Muli", sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: #ffffff;
    padding: 9px 38px;
    margin-left: 10px;
  }

  .btn-prmry {
    width: 172px;
    max-width: 100%;
    padding: 9px;
    margin-left: 43px;
    font-weight: 700;
  }
}

.login-header {
  background: $white;
  display: flex;
  padding: 9px 26px;
  align-items: center;
  justify-content: space-between;
  @include respond-to("large") {
    padding: 9px 15px;
  }
  @include respond-to("small") {
    padding: 9px 10px;
  }

  .brand-logo {
    display: flex;

    img {
      width: 96px;
    }
  }
}

.user-info {
  position: relative;
  summary,
  div {
    position: relative;
    color: rgba(0, 0, 0, 0.8);
    font-size: 18px;
    cursor: pointer;
    padding-right: 26px;
    background: url(../images/down-arrow.png) no-repeat right center;
    text-transform: capitalize;
    span {
      font-size: small;
    }
  }

  ul {
    position: absolute;
    top: 50px;
    right: 0;
    z-index: 99;
    background: $white;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-top: 0;
    padding: 5px 0;
    border-radius: 0 0 4px 4px;

    a {
      font-size: 18px;
      padding: 8px 20px;
      display: block;
      white-space: nowrap;
      color: rgba(0, 0, 0, 0.8);
    }

    span {
      font-size: 18px;
      padding: 8px 20px;
      display: block;
      white-space: nowrap;
      color: rgba(0, 0, 0, 0.8);
    }
  }
}

.breadcrumb {
  display: flex;
  margin: 20px 0 10px;
  li {
    font-size: 16px;
    line-height: 40px;
    margin-right: 5px;
    text-transform: capitalize;
  }
  a {
    color: $blue-btn;
    text-decoration: underline;
  }
  span {
    color: rgba(0, 0, 0, 0.8);
  }
}

.filter-container {
  padding: 0 26px;
  margin: 30px 0 20px 0;
  @include respond-to("large") {
    padding: 0 15px;
    margin: 0 0 20px 0;
  }
  @include respond-to("small") {
    padding: 0 10px;
    margin: 0 0 20px 0;
  }
  &.barcode-scanner {
    padding: 0;
  }

  .form-group button {
    @include respond-to("xsmall") {
      margin-top: 10px;
    }
  }
  strong {
    margin-right: 14px;
    color: $btn-blue;
    font-size: 16px;
  }

  .form-group {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @include respond-to("small") {
      flex-wrap: wrap;
    }

    label {
      margin-bottom: 0;
    }
  }

  .form-control {
    height: 41px;
    line-height: 41px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.6);
    max-width: 138px;
    margin-right: 10px;
    font-weight: 600;
  }

  .date-range-group {
    display: flex;
    margin-right: 10px;

    .form-control {
      &:first-child {
        margin-right: -4px;
      }
    }
  }

  .btn-prmry {
    margin-left: 43px;
  }

  .radio-group {
    display: flex;
    align-items: center;

    input {
      display: none;

      & + label {
        position: relative;
        margin-right: 5px;
        font-family: $font-muli;
        font-weight: 500;
        font-size: 15px;
        color: rgba(0, 0, 0, 0.8);
        margin: 0 15px 0 0;
        display: flex;
        cursor: pointer;

        &::before {
          font-size: 10px;
          border: 2px solid $orange;
          background: $white;
          cursor: pointer;
          width: 14px;
          height: 14px;
          text-align: center;
          border-radius: 100%;
          content: "";
          margin-right: 5px;
          display: inline-block;
        }
      }

      &:checked {
        & + label {
          &::before {
            background: $orange;
          }
        }
      }
    }
  }
}
